@tailwind base;
@tailwind components;
@tailwind utilities;

.primaryColorText {
    color: #015CB8;
}
.primaryColorBack {
    background: #015CB8;
}
